import { useEffect } from 'react';

type Hotkey =
  | string
  | {
      ctrlKey?: boolean;
      shiftKey?: boolean;
      altKey?: boolean;
      metaKey?: boolean;
      code: string;
    };

interface ExceptionRule {
  selector: string;
  keys: Hotkey[];
}

const DefaultExceptionSelectors: ExceptionRule[] = [
  {
    selector: '.quiz-modal',
    keys: ['ArrowLeft', 'ArrowRight', 'Escape', 'Space']
  }
];

const isMatching = (event: KeyboardEvent, hotkey: Hotkey) => {
  if (typeof hotkey === 'string') {
    return event.code === hotkey;
  }
  const compare: Partial<Hotkey> = {
    code: event.code
  };
  if (hotkey.ctrlKey !== undefined) {
    compare.ctrlKey = event.ctrlKey;
  }
  if (hotkey.shiftKey !== undefined) {
    compare.shiftKey = event.shiftKey;
  }
  if (hotkey.altKey !== undefined) {
    compare.altKey = event.altKey;
  }
  if (hotkey.metaKey !== undefined) {
    compare.metaKey = event.metaKey;
  }
  return Object.keys(hotkey).every(key => hotkey[key as keyof Hotkey] === compare[key as keyof Hotkey]);
};

const isInputElement = (element: HTMLElement): boolean => {
  if (!element) {
    return false;
  }
  const tagName = element.tagName?.toUpperCase() ?? '';
  return ['INPUT', 'TEXTAREA', 'SELECT'].includes(tagName) || element.isContentEditable;
};

/**
 * A React hook that provides exclusive keyboard hotkey handling.
 *
 * This hook allows you to register one or more keyboard hotkeys that will be
 * triggered exclusively globally as long as user is not typing in an input
 * or an element matching one of the provided `exceptionSelectors` is present.
 * When a registered hotkey is detected, the provided callback function will
 * be executed, and the keyboard event will be prevented from propagating
 * further.
 *
 * @param keys - A single hotkey or an array of hotkeys to register. Each
 * hotkey can be a string representing a key code (@see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code),
 * or an object with
 * optional modifier keys (ctrl, shift, alt, meta) and a required `code`
 * property.
 * @param callback - A function that will be called when any of the
 * registered hotkeys are triggered. The function will receive the original
 * keyboard event as its argument.
 * @param exceptionSelectors - An array of objects defining exceptions.
 * Each object contains:
 *   - `selector`: A CSS selector. If the element exists in the DOM, specific hotkeys will be ignored.
 *   - `keys`: An array of hotkeys that should be ignored when this selector is present.
 * @returns A cleanup function that can be used to remove the event listener
 * when the component unmounts.
 */
export function useExclusiveHotkeys(
  keys: Hotkey | Hotkey[],
  callback: (event: KeyboardEvent) => void,
  exceptionSelectors: ExceptionRule[] = DefaultExceptionSelectors
) {
  useEffect(() => {
    const hotkeys = Array.isArray(keys) ? keys : [keys];
    const handler = (event: KeyboardEvent) => {
      const target = event.target as HTMLElement;

      // ignore keyboard shortcut when user is inputting on any input
      if (target && isInputElement(target)) {
        return;
      }

      for (const { selector, keys: ignoredKeys } of exceptionSelectors) {
        if (document.querySelector(selector)) {
          for (const ignoredKey of ignoredKeys) {
            if (isMatching(event, ignoredKey)) {
              return; // Ignore this hotkey if selector is present
            }
          }
        }
      }

      if (target && target.shadowRoot) {
        // ignore shadow DOM elements
        return;
      }

      for (const hotkey of hotkeys) {
        const matching = isMatching(event, hotkey);
        if (matching) {
          event.preventDefault();
          event.stopImmediatePropagation();
          callback(event);
          return;
        }
      }
    };
    window.addEventListener('keydown', handler, true);
    return () => {
      window.removeEventListener('keydown', handler, true);
    };
  }, [keys, callback, exceptionSelectors]);
}
